<template>
  <component :is="commonComponent" id="privacy" classs="privacy ">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-row">
        Discover comprehensive information on international {{ domainConfig['IAmTitle'] }}, offering
        insights and
        updates for enthusiasts
        worldwide.
      </div>

      <div class="common-content-head">
        <strong>Information Collection</strong>
      </div>

      <div class="common-content-row">
        We collect user information through Firebase to optimize website performance. This
        information includes, but is not
        limited to:
      </div>

      <div class="common-content-row">
        <ul>
          <li>Browser type</li>
          <li>Access times and dates</li>
          <li>Pages and features accessed</li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Use of Information</strong>
      </div>

      <div class="common-content-row">
        The information collected is used for the following purposes:
      </div>

      <div class="common-content-row">
        <ul>
          <li>Improving website performance and user experience</li>
          <li>Analyzing user behavior and trends</li>
          <li>Maintaining and enhancing our services</li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Information Sharing</strong>
      </div>

      <div class="common-content-row">
        We do not sell, trade, or transfer your personal information to external parties, except
        under the following
        circumstances:
      </div>

      <div class="common-content-row">
        <ul>
          <li>To comply with laws or government requests</li>
          <li>To protect our rights, property, or safety</li>
          <li>In the event of a merger, acquisition, or sale of assets</li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Security</strong>
      </div>

      <div class="common-content-row">
        We take reasonable measures to protect your personal information from unauthorized access,
        use, or disclosure.
        However, please note that internet transmissions are not entirely secure, and we cannot
        guarantee absolute security.
      </div>

      <div class="common-content-head">
        <strong>Children’s Privacy</strong>
      </div>

      <div class="common-content-row">
        We do not knowingly collect personal information from children under the age of 13. If we
        discover that we have
        inadvertently collected such information, we will take immediate steps to delete it.
      </div>

      <div class="common-content-head">
        <strong>Modification of Privacy Policy</strong>
      </div>

      <div class="common-content-row">
        We reserve the right to modify this privacy policy at any time. Any updates will be posted
        on the website
        <strong>{{ domainConfig["IAmURL"] }}</strong> and will take effect immediately upon posting.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Privacy Policy | ${this.domainConfig['meta'].title}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig['meta'].des}`
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>