import { mapGetters } from 'vuex'
// import '@/css/home.scss'
import '@/css/common.scss'

export default {
	computed: {
		...mapGetters({
			host: 'host',
			showDebug: 'showDebug',
			deviceType: 'deviceType',
			domainConfig: 'domainConfig',
		}),
	},
}
